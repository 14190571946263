@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(180deg, #E3EFFF 0%, rgba(255, 255, 255, 0.00) 100%);
}

.container.container__container{
  width: 50%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

