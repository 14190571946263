


  form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  input,
  textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid #1F6CDD;
    resize: none;
  }
  .btn{
    padding: 10px 20px;
    background: #1F6CDD;
    color: white;
    border-radius: 0.5rem;
    margin-top: 1.2rem;
  }

  @media screen and (max-width: 1024px){
    .container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }

  
  }



@media screen and (max-width: 754px){
    .help{
        border: none !important;
    }
}





footer{
    background: rgb(247, 247, 247);
    height: auto;
    width: 100%;
    padding-top: 40px;
    color: rgb(0, 0, 0);
}

footer .content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

footer .content h3{
    font-weight: 400;
    font-size: 1.8rem;
    text-transform: capitalize;
    line-height: 3rem;

}

footer .content p{
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
    
}

.socials{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 3rem 0;
}

.socials li{
    margin: 0 10px;
}

.socials a{
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.footer-bottom{
    background: rgb(54, 51, 51);
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

.footer-bottom p{
    color: #fff;
}


.accordion li{
    height: fit-content;
    list-style: none;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
               -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.accordion li label{
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}
 
label::before{
    content: '+';
    font-size: 24px;
    font-weight: 600;
    margin-right: 10px;
}

input[type='radio']{
    display: none;
}

.accordion .content{
    color: #555;
    padding: 0 10px;
    line-height: 26px;
    max-height: 0;
    overflow: hidden;
    transition: max-hight 0.1s, padding 0.1s;
}

.accordion input[type="radio"]:checked + label + .content{
    max-height: 400px;
    padding: 10px 10px 20px;
}

.accordion input[type="radio"]:checked + label::before{
    content: '-';
}


.image{
    animation: float 3s linear infinite;
}

@keyframes float{
    0%,100%{
        transform: translateY(0rem);
    }
    50%{
        transform: translateY(-2rem);
    }
}

.help{
    border: 1px solid black;
}